button {
    //box-shadow: inset 0 0 0 1px $main-dark-color;
    background: linear-gradient(90deg, #FE6A9C 0%, #FE846D 100%);
    border-radius: 0.2em;
    border: none;
    color: #fff; //$main-dark-color;
    font-size: 1em;
    padding: 0.5em 1em;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-size: 100%;
    &:hover {
        //background: rgba(0,0,0,0.2);
        background-size: 150%;
    }
    &.light {
        box-shadow: inset 0 0 0 1px #fff;
        color:  #fff;
        &:hover, &:focus {
            outline: none;
            background: rgba(255,255,255,0.15);
        }
    }
    &.disabled {
        cursor: not-allowed;
    }
}




.document {
    list-style: none;
    margin: 0;
    padding: 0;
    background: #fff;

    &.document-error {
      padding: 1em;
    }
  
    .document-item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ddd;
      &:last-child {
        border-bottom: none;
      }
      
  
      .icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .text {        
        overflow: hidden;
        text-overflow: ellipsis;
      }
      button {
        box-shadow: none;
        text-align: left;
        display: flex;
        justify-content: space-between;
        width: -webkit-fill-available;
        font-weight: normal;
        padding: 0.5em 1em;        
        color: #333;
        background: #efefef;
        transition: box-shadow 0.2s ease-in-out;
        &:hover {
          box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
  

