.customers-list {
    padding: 0;
    margin: 0;
    list-style: none;
    .customer-item {
        background: #fff;
        padding: 0.8em;
        border-radius: 0.2em;
        color: #555;
        font-weight: 600;
        cursor: pointer;
        transition: 0.2s all ease;
        &:nth-child(even){
            background: transparent;
        }
        &:hover {            
            color: #000;
        }
    }
}