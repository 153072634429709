
* {
    font-family: 'Quicksand', sans-serif;
    box-sizing: border-box;
    font-size: 18px;
}

body {
    margin: 0;
}


$main-dark-color: #26262E;
$main-text-color: #33475b;

@import 'grid';
@import 'loading';
@import 'button';
@import 'typo';
@import 'fields';
@import 'boxes';
@import 'libaries';
@import 'list';