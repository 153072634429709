
$main-input-color: #2980b9;
$error-input-color: #c0392b;

.field-import {
  width: 100%;
  position: relative;
  margin: 1.4em 0em;

  input,
  select {
    width: 100%;
    padding: 0.4em;
    z-index: 10;
    border: none;
    border-bottom: 1px solid #999;
    background: transparent;
    font-size: 1em;
    

    &:focus {
      border-color: $main-input-color;
      outline: none;
      box-shadow: none;

      +label {
        color: $main-input-color;
        transform: translateY(-1.5em) translateX(-0.25em);
      }

      +label span {
        color: $main-input-color;
      }
    }

    &.ng-touched.ng-invalid {
      border-color: $error-input-color;

      +label,
      +label span {
        color: $error-input-color;
      }
    }
  }

  label {
    display: block;
    transition: all 0.1s ease-in-out;
    position: absolute;
    top: 0.25em;
    left: 0.25em;
    pointer-events: none;
    z-index: 5;
    color: #777;
    font-size: 1em;
    white-space: nowrap;

    span {
      color: #777;
      font-size: 1em;
    }

    &:focus,
    &:active,
    &.has-value {
      color: $main-input-color;
      transform: translateY(-1.5em) translateX(-0.25em);

      span {
        color: $main-input-color;
      }
    }
  }

  &.light {
    input,
    select {
      border-color: #fff;
      color: #fff;
    } 
    label {
      color: #fff;
      span {
        color: #fff;
      }
    }
  }

  .help {
    position: absolute;
    right: 0;
    top: 0;

    .help-text {
      position: absolute;
      right: 0.8em;
      background: #555;
      top: 0.8em;
      color: #fff;
      font-size: 0.8em;
      border-radius: 0.4em 0em 0.4em 0.4em;
      //width: 100%;
      transition: 0.1s all ease-in-out, .3s opacity;
      max-width: 0;
      overflow: hidden;
      padding: 0;
      min-width: 0;
      opacity: 0;
      height: 3em;
      z-index: 10000;

    }

    &:hover {
      .help-text {
        opacity: 1;
        height: auto;
        max-width: 35em;
        padding: 0.4em;
        min-width: 16em;
      }
    }
  }
}


//guarantee
.block-wrapper {
  background: transparent !important;
  .block {
    background: #fff !important;
  }
}