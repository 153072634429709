

/***********************************************************************************/
/*************************************SIDEBAR***************************************/
/***********************************************************************************/

section {
    &.sidebar {
        //background: $main-dark-color;
        background: #fff;
        box-shadow: 0 0 1em 0 rgba(44, 46, 255, 0.1);
        z-index: 10;
    }
}