

$size_1: 12%;
$size_2: 16,6666666666667%;
$size_3: 25%;
$size_4: 33.3333333333333%;
$size_5: 41.66666666666667%;
$size_6: 50%;
$size_7: 58.333333333333%; 
$size_8: 66.66666666666667%;
$size_9: 75%;
$size_10: 83.33333333333333%;
$size_11: 90.9090909090901%;
$size_12: 100%;

$margin_size: 1em;


.row {
    .column {
        display: block;
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
    }
    &.multiline {    
        flex-wrap: wrap;
    }
}


@media (min-width: 640px) {

}

@media (min-width: 768px) {

}

@media (min-width: 1024px) {
    .only-mobile {
        display: none;
    }
    .row {
        display: flex;
        .column {
            flex: none;
            &.is-1 { width: $size_1; } 
            &.is-2 { width: $size_2; } 
            &.is-3 { width: $size_3; } 
            &.is-4 { width: $size_4; }
            &.is-5 { width: $size_5; } 
            &.is-6 { width: $size_6; }
            &.is-7 { width: $size_7; } 
            &.is-8 { width: $size_8; } 
            &.is-9 { width: $size_9; } 
            &.is-10 { width: $size_10; } 
            &.is-11 { width: $size_11; } 
            &.is-12 { width: $size_12; }
        }
        &.margin {
            margin-left: -#{$margin_size};
            margin-right: -#{$margin_size};
            margin-top: -#{$margin_size};
            > .column {
                padding: $margin_size;
            }
        }
    }
}

@media (min-width: 1200px) {
}
