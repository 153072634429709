

p,h1,h2,h3,h4,h5,h6,ul {
    margin: 0.5em 0;
}

h1 {
    font-size: 1.4em;
    color: $main-dark-color !important;
}
h2 {
    font-size: 1.2em;
    color: $main-dark-color !important;
}

.small-text {
    font-size: 0.8em;
}